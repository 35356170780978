// import http from "@/utils/request";

import dmshttp from "@/utils/dmsrequest";
export function getProductList(data) {
    return dmshttp({
        url: 'getPurchaseOrderDetailList',
        data
    })
}

export function inStock(data) {
    return dmshttp({
       
        url: 'purchaseOrderBatchIn',
        data
    })
}
export function inStockSignle(data) {
    return dmshttp({
       
        url: 'purchaseOrderIn',
        data
    })
}

// 查仓库
export function findBaseData(data) {
    return dmshttp({
        url: 'findBaseData',
        data
    })
}


// 列表详情
export function findDetail(data) {
    return dmshttp({
        url: 'findPurchaseOrderDetail',
        data
    })
}

// 导出数据
export function exportDate(data) {
    return dmshttp({
        url: 'exportPurchaseOrderDetailList'+'&type=1',
        data,
        responseType: 'blob'
    })
}


export function getImg(data) {
    return dmshttp({
      url: 'toOmsView',
      data
    })
  }