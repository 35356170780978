import {
  getOrderDetail,
  getImg
} from "../details/api";
import {
  saveEvaluate,
  getEvaluate,
  inStockSignle,
  findBaseData,
  cancleSap,
  cancleSap1160,
  confirmReceipt,
  saveDeliveryData,
  queryAppraise,
} from "./api";
import {sign} from '../../order/channelOrder/progress/api'
import {
  findDetail,
  inStock
} from './../dmsProcurementProgress/api'
import Clipboard from "clipboard";
import {
  addCart
} from "@/views/order/api";
import logisticsEvaluationModal from '../components/logisticsEvaluationModal.vue'
import consulationModal from '../components/consultationPopup.vue'
import {env} from "@/common/constant";
export default {
  name: "purchaseOrderprogress",
  components: {
    consulationModal,
    logisticsEvaluationModal,

  },
  data() {
    return {
      pageLoadFlag: true,
      desc: ["非常差", "差", "一般", "好", "非常好"],
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/order/detail",
          name: "orderDetail",
          title: "订单详情"
        },
        {
          path: "/order/progress",
          name: "progress",
          title: "发货进度"
        }
      ],
      tabActiveIndex: 0,
      visible: false,
      orderDetail: {
        orderHeader: {}
      },
      evaluate: {
        productLevel: 3,
        deliveryLevel: 3,
        logisticsLevel: 3,
        evaluationContent: "",
        creator: "",
        createdDate: "",
        serviceLevel: 1,
        show: false, //是否显示评价
        finished: false //是否评价完成
      },
      tags: [{
        label: "结果满意",
        val: false
      },
        {
          label: "回复速度快",
          val: false
        },
        {
          label: "态度好",
          val: false
        }
      ],
      erpList: [], //发货单列表
      selSapOrder: {
        sap: null,
        logistics: [],
        machineCodes: []
      }, //当前选中的套单
      // isDisabled:false
      stock: [], // 仓库列表
      stockData: [],
      selKeys2: null,
      selRows: [],
      tablecolumns: [{
        title: "仓库",
        dataIndex: "name",
        key: "name"
      }],
      pager: {
        count: "",
        pageNo: 1,
        pageSize: 10
      },
      modelShowSign: false,
      cherkInfo: null,
      shopKey: '',
      modelShopShow: false,
      imgURL: [],
      // imgURLItem:'',
      isHdyxActiveFlag:false, // 回单影像是否可点击 (初始化接口请求)
      onluyOneMessage: false,
      evaluateTip: '',
      sapCancelShow: false,
      confirmReceiptShow: false,
      nowStatuses: 'kd3',
      // 订单是否已发货
      orderOverallStatus: false,
      modelConsulation: false,
      modellogisticsEvaluation: false,
      reportModal: false,
      giveUpModal: false,
      messageInfo: '',
      consultationList: [], // 咨询详情
      evaluationObj: null,
      mapUrl:'',
      key: new Date().getTime(),
      showDisplay:false,
      sapCancelLoading: false,
      env:env,
    }
  },
  watch:{
    mapUrl: {
      handler(val) {
        this.key = new Date().getTime()
        this.mapUrl= val
      },
      immediate: true,
    }
  },
  computed: {
    testList() {
      // 当数据不需要完全显示的时候
      if(this.showDisplay == false){
        let testList = []; // 定义一个空数组
        // 先显示前三个
        if(this.consultationList.length > 3){
          for(var i = 0;i < 3;i++){
            testList.push(this.consultationList[i])
          }
        }else{
          testList = this.consultationList;
        }
        return testList; // 返回当前数组
      }
      else{//展示全部数据
        return this.consultationList;
      }
    },
    totalProd: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += item.qty;
        });
      }
      return total;
    },
    totalPrice: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += (item.qty * (item.basePrice * 100)) / 100;
        });
      }
      return total;
    },
    totalLoadVolume: function () {
      let totalVolulme = 0
      if (this.selSapOrder && Array.isArray(this.selSapOrder.sap)) {
        this.selSapOrder.sap.forEach(item => {
          totalVolulme += (Number(item.productInfo.loadVolume) * Number(item.erpQty));
        });
      }
      return totalVolulme;
    },
    totalAdvancePrice: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += item.advancePrice;
        });
      }
      return total;
    },
    totalProduct: function () {
      let totalProduct = 0
      // selSapOrder.sap.erpQty
      if (this.selSapOrder && Array.isArray(this.selSapOrder.sap)) {
        this.selSapOrder.sap.forEach(item => {
          totalProduct += item.erpQty;
        });
      }
      return totalProduct;
    },
    totalxj: function () {
      let total = 0
      // selSapOrder.sap.erpQty
      if (this.selSapOrder && Array.isArray(this.selSapOrder.sap)) {
        this.selSapOrder.sap.forEach(item => {
          total += item.xj;
        });
      }
      return total;
    },
  },
  mounted() {
    window.selSapOrder = this.selSapOrder

    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id);
      this.breadcrumbData[1].path = "/order/detail?id=" + this.$route.query.id;

    } else {
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
  },
  methods: {
    // 查询咨询评价记录接口
    queryAppraise(orderCode) {
      queryAppraise({
        orderCode: orderCode
      }).then(res => {
        if(res.data.code == 0) {
          this.consultationList = res.data.type1
          if(JSON.stringify(res.data.type3) != '{}') {
            res.data.type3.tagNameList = res.data.type3.tagNames ? res.data.type3.tagNames.split(',') : []
            this.evaluationObj = res.data.type3
          }else {
            this.evaluationObj = null
          }
        }else {
          this.$message.warning( res.data.msg || '系统异常，请稍后重试')
        }
      })
    },
    getpjInfo(){
      this.getDetail(this.$route.query.id);

    },
    // 投诉举报
    getInfo(formData){
      // console.log(form)
      // 提交咨询表单
      this.getDetail(this.$route.query.id);
    },

    giveUp(){

    },

    getConfirm() {
      let params = {
        sapId: this.orderLineId || '15555698390'
      }
      confirmReceipt(this.orderLineId).then(res => {
        // 确认收货弹窗
        if (res.data.code == 0) {
          this.$message.success(res.data.msg);
          if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
          }
        }
      })
    },
    goConfirmReceipt() {
      this.confirmReceiptShow = true;
    },
    //获取msg提示信息
    getMessage() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: '14246556242'
      })
      this.evaluateTip = msg.msgDesc
    },
    // // 回单影像
    // requestImg(documentNum) {
    //   let data = {
    //     cisCode: this.$store.state.user.userInfo.customer.cisCode,
    //     documentNum
    //   }
    //   getImg(data).then(res => {
    //     var realData = JSON.parse(res.data);
    //     if (realData.data) {
    //       this.imgURL = realData.data;
    //       this.onluyOneMessage = true;
    //     } else {
    //       this.$message.warning('暂无回单影像');
    //     }

    //   })
    // },
    // 回单影像
    requestImg(documentNum) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        documentNum
      }
      getImg(data).then(res => {
        if (res.data) {
          var realData = JSON.parse(res.data);

          if (realData.data) {
            this.imgURL = realData.data;
            // this.imgURLItem = realData.data[0];
            this.isHdyxActiveFlag = true;
          } else {
            this.isHdyxActiveFlag = false;
          }
        } else {
          this.isHdyxActiveFlag = false;
        }
      })
    },
    openImg(){
      if(this.isHdyxActiveFlag){
        this.onluyOneMessage = true;
      }else{
        this.$message.warning('暂无回单影像');
      }
    },
    // 取消订sap单提示
    sapCancelClick() {
      this.sapCancelShow = true;
    },
    decideCancle () {
      this.sapCancelLoading = true;
      if (this.selSapOrder.orgCode == 1160) {
        let data = {
          sapOrderCode: this.selSapOrder.sap[0].sapOrderCode,
          serviceCode: 'cancelZhshSapOrder'
        }
        cancleSap1160(data).then(res=> {
          //console.log(res)
          if (res.data.code == 0) {
            this.$message.success("提交成功");
            this.getDetail(this.$route.query.id);
            this.sapCancelLoading = false;

          } else {
            this.$message.warning(res.data.msg);
            this.modelShopShow = false;
            this.getDetail(this.$route.query.id);
            this.sapCancelLoading = false;
          }
        })
      }else {
        this.cancleSap()
      }
    },
    // 取消sap订单
    cancleSap() {
      let data = {
        sapOrderCode: this.selSapOrder.sap[0].sapOrderCode
      }
      cancleSap(data).then(res => {
        if (res.data.code == 0) {
          this.sapCancelLoading = false;
          this.$message.success("提交成功");
          this.modelShopShow = false;
          this.sapCancelShow = false;
        } else {
          this.sapCancelLoading = false;
          this.$message.warning(res.data.msg);
          this.modelShopShow = false;
          this.sapCancelShow = false;
        }
      }).catch(e=>{
        this.$message.error("提交失败");
        this.modelShopShow = false;
        this.sapCancelShow = false;
      })
    },
    // 弹窗接口对接
    getReason(value,) {
      this.selKeys2 = value
      this.inSignle()
    },
    getChooseShop(value) {
      this.selKeys2 = value.ck
      this.shopKey = value.shop
      this.allIn2()

    },
    // 批量入库 -- 添加门店参数
    allIn2() {
      let purchaseOrderId = []
      purchaseOrderId.push(this.cherkInfo.sap.purchaseOrderId)
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        warehouseId: this.selKeys2,
        directOrderIds: [],
        storeCode: this.shopKey,
        purchaseOrderIds: purchaseOrderId
      };
      inStock(data).then(res => {
        let request = JSON.parse(res.data);
        if (request.code == 0) {
          this.$message.success("入库成功");
          this.modelShopShow = false;
          this.getData();
        } else {
          this.$message.warning(request.msg);
          this.modelShopShow = false;
        }
      });
    },
    openStock(data) {
      this.cherkInfo = data
      this.modelShopShow = true;
    },
    async openStock2(data) {
      this.modelShowSign = true;
      // this.infoinStock = data
      // 查询当前选择的列表详情接口
      let adata = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        loginType: "CS",
        purchaseOrderId: data.sap.purchaseOrderId
      }
      let res = await findDetail(adata)
      let request = JSON.parse(res.data)
      this.cherkInfo = request.data
    },
    // 单个入库
    inSignle() {
      this.cherkInfo.purchaseOrderItem.forEach(item => {
        // 入库数量
        item.shippedBqty = item.outQty > 0 ? item.outQty - (Number(item.orderedQty) - item.waitStockBQty) : 0;
        item.warehouseId = this.selKeys2;
      });
      let Finished = null
      if (this.cherkInfo.purchaseOrderItem[0].shippedBqty == this.cherkInfo.purchaseOrderItem[0].waitStockBQty) {
        Finished = true
      }
      if (this.cherkInfo.purchaseOrderItem[0].shippedBqty < this.cherkInfo.purchaseOrderItem[0].waitStockBQty) {
        Finished = false
      }


      inStockSignle({
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        data: {
          isFinished: Finished,// 
          purchaseOrderId: this.cherkInfo.id, // 
          purchaseOrderItem: this.cherkInfo.purchaseOrderItem// 
        }
      }).then(res => {
        let request = JSON.parse(res.data);
        if (request.code == 0) {
          this.$message.success("入库成功");
          this.modelShowSign = false;
          this.getData();
        } else {
          this.$message.warning(request.msg);
          this.modelShowSign = false;
        }
      });
    },

    // 加入购物车
    addCart(proid, orgid) {
      let data = {
        productId: proid,
        orgId: orgid,
        num: 1
      }
      addCart(data).then(res => {
        if (res.data.code == 0) {
          this.$message.success("加入购物车成功");
        } else {
          this.$message.warning(res.data.msg);
        }
      }).catch(error => {
        this.$message.warning('加入购物车失败');
        console.log('加入购物车', error);
      })
    },
    getProdImg() {
      if (!this.selSapOrder.sap.productInfo) {
        return ''
      }
      return (
        this.selSapOrder.sap.productInfo.materialGroup +
        "/" +
        this.selSapOrder.sap.productInfo.productCode +
        "/180-180/" +
        this.selSapOrder.sap.productInfo.picture
      );
    },
    getProdErrorImg() {
      if (!this.selSapOrder.sap.productInfo) {
        return ''
      }
      return (
        "assets/new/images/product/" +
        this.selSapOrder.sap.productInfo.materialGroup +
        ".jpg"
      );
    },
    sumitEvaluate() {
      const params = {
        erpId: this.selSapOrder.id,
        orderId: this.selSapOrder.orderId,
        orgId: this.selSapOrder.orgId,
        productLevel: this.evaluate.productLevel,
        serviceLevel: this.evaluate.serviceLevel,
        logisticsLevel: this.evaluate.logisticsLevel,
        deliveryLevel: this.evaluate.deliveryLevel,
        evaluationContent: this.evaluate.evaluationContent,
        loginType: "cs"
      };
      saveEvaluate(params).then(res => {
        if (res.data == "success") {
          this.$notification.success({
            message: "评价成功",
            duration: 3 //延时2.5s
          });
          // this.isDisabled = true
        } else {
          this.$notification.error({
            message: "评价失败",
            duration: 3 //延时2.5s
          });
        }
        this.getEvaluate()
      });
    },
    getEvaluate() {
      // 之前是默认三颗星 liufei提出修改为默认没有星星
      this.evaluate.productLevel = 0;
      this.evaluate.serviceLevel = 3;
      this.evaluate.logisticsLevel = 3;
      this.evaluate.deliveryLevel = 3;
      this.evaluate.evaluationContent = "";
      this.evaluate.finished = false;
      this.evaluate.show = false;
      if (!this.selSapOrder) {
        return
      }
      const params = {
        erpId: this.selSapOrder.id,
        orderId: this.selSapOrder.orderId,
        orgId: this.selSapOrder.orgId
      };
      getEvaluate(params).then(res => {
        if (res.data.productEvaluate) {
          this.evaluate.productLevel = parseInt(
            res.data.productEvaluate.productLevel
          );
          this.evaluate.serviceLevel = parseInt(
            res.data.productEvaluate.serviceLevel
          );
          this.evaluate.logisticsLevel = parseInt(
            res.data.productEvaluate.logisticsLevel
          );
          this.evaluate.deliveryLevel = parseInt(
            res.data.productEvaluate.deliveryLevel
          );
          // 之前评分如果为0 的时候 会显示undefined 此处进行了判断
          if (this.evaluate.productLevel !== 0) {
            this.evaluate.score_text = `${this.desc[this.evaluate.productLevel - 1]}（${this.evaluate.productLevel}分）`
          } else {
            this.evaluate.score_text = `${this.desc[this.evaluate.productLevel]}（${this.evaluate.productLevel}分）`
          }

          this.evaluate.evaluationContent =
            res.data.productEvaluate.evaluationContent;
          this.evaluate.creator = res.data.productEvaluate.creator;
          this.evaluate.createdDate = res.data.productEvaluate.createdDate;
          this.evaluate.finished = true;
          this.evaluate.show = true;
        } else {
          let finished
          if (this.selSapOrder && this.erpList.length) {
            finished = this.erpList.filter(it => it.statusName == '已商收')
          }
          if (
            this.selSapOrder.nowStatuses == "O8" ||
            this.selSapOrder.nowStatuses == "O11" || finished.length > 0
          ) {
            this.evaluate.show = true;
          } else {
            this.evaluate.show = false
          }
        }
      });
    },
     // 获取签名
     getSign(data){
      return sign(data)
    },
   async getDetail(id) {
      this.pageLoadFlag = true;
      getOrderDetail(id)
      .then(async(res) => {
          this.orderDetail = res.data;
          let orderCode = res.data.orderHeader.orderCode
          this.queryAppraise(orderCode)
          this.sapOrderItems = [];
          let idKey = this.$route.query.id - 0
          let temp = this.orderDetail.nowStatuses
          temp = JSON.stringify(temp)
          temp = `${temp}`
          let temp1 = temp.split(':')[0].split('{')
          // debugger
          temp = temp.split(':')[1].split('}')[0]

          this.orderLineId = Object.keys(this.orderDetail.nowStatuses)[0]

          // debugger
          this.nowStatuses = temp
          this.orderLogisticsStatus = Object.values(this.orderDetail.logisticsStatus)[0]
          this.orderOverallStatus = this.orderLogisticsStatus.find(i => i.statusCode == 'kd17')
          this.orderErpList = this.orderDetail.erpList
          if (!res.data.erpList || res.data.erpList.length < 1) {
            return
          }
          if (this.$route.query.code) {
            this.tabActiveIndex = this.$route.query.code
          } else {
            this.tabActiveIndex = res.data.erpList[0].sapOrderCode
          }
          const signData = await this.getSign({content:this.tabActiveIndex,systemSource:'CIS'})
          this.mapUrl=`https://tms.hisense.com/route/index.html#/?orderNumber=${ this.tabActiveIndex }&systemSource=CIS&sign=${signData.data?signData.data:''}`  
    
          // this.tabActiveIndex = 0;
          this.erpList = res.data.erpList.map(item => {
            item.sap = this.orderDetail.sapOrderItems[item.id];
            item.logistics = this.orderDetail.logisticsStatus[item.id];
            item.nowStatuses = this.orderDetail.nowStatuses[item.id]; //现在订单状态 O8,O11 时可以评价 开头是欧不是零
            item.machineCodes = this.orderDetail.machineCodes[item.id]; // 出库机编条码
            return item;
          });
          this.erpList.forEach(item => {
            // 物流信息中的备注null替换成""
            item.logistics.forEach(remarkItem => {
              if (remarkItem.remark) {
                remarkItem.remark = remarkItem.remark.replace(/null/g, "")
              }
            })

          })
          this.selSapOrder = this.erpList[0];
          // 修复报错问题
          if (this.selSapOrder && this.selSapOrder.sap) {
            this.selSapOrder.sap.forEach(proItem => {
              if (proItem.billPrice.toString().indexOf('.') == '-1') {
                proItem.billPrice = Number(proItem.billPrice).toFixed(2)
              }
            })
          }
          if (this.selSapOrder && this.selSapOrder.sap) {
            this.selSapOrder.sap.forEach(proItem => {
              if (proItem.basePrice.toString().indexOf('.') == '-1') {
                proItem.basePrice = Number(proItem.basePrice).toFixed(2)
              }
            })
          }
          if(this.selSapOrder.sap[0].sapOrderCode){
            this.requestImg(this.selSapOrder.sap[0].sapOrderCode);
          }
        })
        .finally(() => {
          this.pageLoadFlag = false;
        });
    },
    goBack() {
      window.close()
    },
    async tabChange(item, index) {
      this.tabActiveIndex = item.sap[0].sapOrderCode
      const signData = await this.getSign({content:this.tabActiveIndex,systemSource:'CIS'})
      // this.mapUrl=`https://dwldev.hisense.com/route/index.html#/?orderNumber=${ this.tabActiveIndex }&systemSource=CIS&sign=${signData.data?signData.data:''}`  
      this.mapUrl=`https://tms.hisense.com/route/index.html#/?orderNumber=${ this.tabActiveIndex }&systemSource=CIS&sign=${signData.data?signData.data:''}`  
     
      this.selSapOrder = this.erpList[index];
    
      if(this.selSapOrder.sap[0].sapOrderCode){
        this.requestImg(this.selSapOrder.sap[0].sapOrderCode);
      }
    },
    handleOk(e) {
      this.visible = false;
    },
    changeChecked(item) {
      item.val = !item.val;
    },
    getPrice(item) {
      return ((item.basePrice || 0) * 100 * (item.qty || 0)) / 100;
    },
    copyOrder() {
      var clipboard = new Clipboard(".copy-box");
      clipboard.on("success", e => {
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制
        this.$message.warning("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
    copyBarcode() {
      var clipboard = new Clipboard(".copyBarcode-box");
      clipboard.on("success", e => {
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制
        this.$message.error("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    }
  }
};