import service from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";
import http from "@/utils/request";

//获取订单详
export function getOrderDetail(id) {
  return service({
    method: "get",
    url: "/order/orderLine.htm",
    params: {
      id: id
    }
  });
}

export function getImg(data) {
  return dmshttp({
    url: 'toOmsView',
    data
  })
}

//  详情获取服务费
export function selectServiceFree(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "/orderServiceFee/selectServiceFee.nd",
    data
  });
}
// 查看用户档案
export function findUserIdByShopAndPhone(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "shopPotentialUser/findUserIdByShopAndPhone.nd",
    data
  });
}
// 获取用户档案
export function queryUserByPhoneAndShopCode(data) {
  return http({
    method: "post",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    url: "orderServiceFee/queryUserByPhoneAndShopCode.nd",
    data
  });
}

// findSalesOrderByBhoOrder
export function findSalesOrderByBhoOrder(data) {
  return dmshttp({
    url: 'findSalesOrderByBhoOrder',
    data
  })
}